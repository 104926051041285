<template>
  <div id="container">
    <b-loading :is-full-page="false" :active="graphLoading"></b-loading>
    <canvas id="notifications-clicks-chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';
let chart;

export default {
  name: 'NotificationsClicksChart',
  props: {
    indexFrom: {
      type: Number,
      required: true,
    },
    indexTo: {
      type: Number,
      required: true,
    },
    chartType: {
      type: String,
      required: true,
    },
    chartLabels: {
      type: Array,
      required: true,
    },
    notifications: {
      type: Array,
      required: false,
      default: null,
    },
    initialNotifications: {
      type: Array,
      required: false,
      default: null,
    },
    initialPageLoads: {
      type: Array,
      required: false,
      default: null,
    },
    pageLoads: {
      type: Array,
      required: false,
      default: null,
    },
    showNotifications: {
      type: Boolean,
      required: true,
    },
    showInitialNotifications: {
      type: Boolean,
      required: true,
    },
    showInitialPageLoads: {
      type: Boolean,
      required: true,
    },
    showPageLoads: {
      type: Boolean,
      required: true,
    },
    graphLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      indexFromPointer: null,
      indexToPointer: null,
      omwChartData: {
        type: this.chartType,
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: { display: false },
          scales: {
            xAxes: [
              {
                ticks: {
                  reverse: true,
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'Daily total',
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      },
    };
  },
  watch: {
    indexFrom() {
      while (this.indexFrom > this.indexFromPointer) {
        this.indexFromPointer++;
        this.omwChartData.data.datasets.forEach((dataset, index) => {
          const dataKey = this.omwChartData.data.datasets[index].dataKey;
          chart.data.datasets[index].data.push(
            this[dataKey][this.indexFromPointer],
          );
        });
        chart.data.labels.push(this.chartLabels[this.indexFromPointer]);
      }
      while (this.indexFrom < this.indexFromPointer) {
        this.indexFromPointer--;
        chart.data.datasets.forEach((dataset, index) => {
          chart.data.datasets[index].data.pop();
        });
        chart.data.labels.pop();
      }
      chart.update();
    },
    indexTo() {
      while (this.indexTo < this.indexToPointer) {
        this.indexToPointer--;
        this.omwChartData.data.datasets.forEach((dataset, index) => {
          const dataKey = this.omwChartData.data.datasets[index].dataKey;
          chart.data.datasets[index].data.unshift(
            this[dataKey][this.indexToPointer],
          );
        });
        chart.data.labels.unshift(this.chartLabels[this.indexToPointer]);
      }
      while (this.indexTo > this.indexToPointer) {
        this.indexToPointer++;
        chart.data.datasets.forEach((dataset, index) => {
          chart.data.datasets[index].data.shift();
        });
        chart.data.labels.shift();
      }
      chart.update();
    },
    chartType() {
      chart.destroy();
      this.omwChartData.type = this.chartType;
      this.chartTypeSettings(this.chartType);
      const ctx = document.getElementById('notifications-clicks-chart');
      chart = new Chart(ctx, this.omwChartData);
    },
    showNotifications() {
      const index = chart.data.datasets.findIndex(
        (dataset) => dataset.dataKey === 'notifications',
      );
      chart.data.datasets[index].hidden = !this.showNotifications;
      chart.update();
    },
    showInitialNotifications() {
      const index = chart.data.datasets.findIndex(
        (dataset) => dataset.dataKey === 'initialNotifications',
      );
      chart.data.datasets[index].hidden = !this.showInitialNotifications;
      chart.update();
    },
    showInitialPageLoads() {
      const index = chart.data.datasets.findIndex(
        (dataset) => dataset.dataKey === 'initialPageLoads',
      );
      chart.data.datasets[index].hidden = !this.showInitialPageLoads;
      chart.update();
    },
    showPageLoads() {
      const index = chart.data.datasets.findIndex(
        (dataset) => dataset.dataKey === 'pageLoads',
      );
      chart.data.datasets[index].hidden = !this.showPageLoads;
      chart.update();
    },
  },
  mounted() {
    this.indexFromPointer = this.indexFrom;
    this.indexToPointer = this.indexTo;
    const ctx = document.getElementById('notifications-clicks-chart');
    this.omwChartData.data.datasets = [];
    if (this.notifications) {
      this.omwChartData.data.datasets.push({
        dataKey: 'notifications',
        label: 'Notifications sent',
        backgroundColor: this.statsColors.notifications,
        borderColor: this.statsColors.notifications,
        tension: 0.4,
        data: this.notifications.slice(0, this.indexFrom + 1),
        hidden: false,
      });
    }
    if (this.initialNotifications) {
      this.omwChartData.data.datasets.push({
        dataKey: 'initialNotifications',
        label: 'Activities',
        backgroundColor: this.statsColors.initialNotifications,
        borderColor: this.statsColors.initialNotifications,
        tension: 0.4,
        data: this.initialNotifications.slice(0, this.indexFrom + 1),
        hidden: false,
      });
    }
    if (this.initialPageLoads) {
      this.omwChartData.data.datasets.push({
        dataKey: 'initialPageLoads',
        label: 'Engaged activities',
        backgroundColor: this.statsColors.initialPageLoads,
        borderColor: this.statsColors.initialPageLoads,
        tension: 0.4,
        data: this.initialPageLoads.slice(0, this.indexFrom + 1),
        hidden: false,
      });
    }
    if (this.pageLoads) {
      this.omwChartData.data.datasets.push({
        dataKey: 'pageLoads',
        label: 'Page Loads',
        backgroundColor: this.statsColors.pageLoads,
        borderColor: this.statsColors.pageLoads,
        tension: 0.4,
        data: this.pageLoads.slice(0, this.indexFrom + 1),
        hidden: false,
      });
    }
    this.omwChartData.data.labels = this.chartLabels.slice(
      0,
      this.indexFrom + 1,
    );
    chart = new Chart(ctx, this.omwChartData);
  },
  methods: {
    chartTypeSettings(type) {
      this.omwChartData.data.datasets.forEach((dataSet, index) => {
        this.omwChartData.data.datasets[index].backgroundColor =
          type === 'line'
            ? '#FFFFFF00'
            : this.omwChartData.data.datasets[index].borderColor;
      });
    },
  },
};
</script>
<style scoped>
#container {
  position: relative;
  height: 30vh;
}
</style>
