<template>
  <b-table
    hoverable
    striped
    :data="monthlySummaries"
    :columns="monthlyDataColumns"
    :loading="loadingMonthlyStats"
  >
    <template slot="footer">
      <div class="buttons">
        <b-button
          type="is-primary"
          icon-left="download"
          icon-pack="fad"
          @click="loadMoreMonths()"
        >
          {{ $t('Load more stats') }}
        </b-button>
        <b-button
          type="is-primary"
          icon-left="download"
          icon-pack="fad"
          @click="saveMonthlyCsv()"
        >
          {{ $t('Export monthly stats') }}
        </b-button>
      </div>
    </template>
  </b-table>
</template>

<script>
import Papa from 'papaparse';
import saveFile from 'save-as-file';
import { DateTime } from 'luxon';

export default {
  name: 'OmwMonthlyStatsTable',
  props: {
    monthlySummaries: {
      type: Array,
      required: true,
    },
    loadingMonthlyStats: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    monthlyDataColumns() {
      const monthlyDataColumns = [];
      monthlyDataColumns.push({
        field: 'monthYearLabel',
        label: this.$t('Month'),
        sortable: true,
        centered: true,
      });
      if (this.$configData.showNotificationStats) {
        monthlyDataColumns.push({
          field: 'totalNotifications',
          label: this.$t('Notifications sent'),
          sortable: true,
          centered: true,
          numeric: true,
          visible: false,
        });
      }
      if (this.$configData.omwStats.showNotificationStats) {
        monthlyDataColumns.push({
          field: 'totalInitialNotifications',
          label: this.$t('Activities'),
          sortable: true,
          centered: true,
          numeric: true,
        });
      }
      monthlyDataColumns.push({
        field: 'totalInitialPageLoads',
        label: this.$t('Engaged activities'),
        sortable: true,
        centered: true,
        numeric: true,
      });
      if (this.$configData.omwStats.showNotificationStats) {
        monthlyDataColumns.push({
          field: 'percentageEngaged',
          label: this.$t('% engaged activities'),
          sortable: true,
          centered: true,
          numeric: true,
        });
      }
      monthlyDataColumns.push({
        field: 'totalPageLoads',
        label: this.$t('Page loads'),
        sortable: true,
        centered: true,
        numeric: true,
      });
      monthlyDataColumns.push({
        field: 'averagePageLoads',
        label: this.$t('Average page loads per engaged activity'),
        sortable: true,
        centered: true,
        numeric: true,
      });
      return monthlyDataColumns;
    },
  },
  methods: {
    saveMonthlyCsv() {
      const csv = Papa.unparse(this.monthlySummaries);
      const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
      saveFile(
        blob,
        `StatsOutput_${DateTime.now().toFormat('dd-MM-yyyy_HH_mm_ss')}.csv`,
      );
    },
    loadMoreMonths() {
      this.$emit('load-more-months');
    },
  },
};
</script>
