<template>
  <div style="padding-top: 10px">
    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>
    <p v-if="error" class="has-text-danger">{{ error }}</p>
    <div v-else class="tile is-parent">
      <div class="tile is-child box">
        <b-field horizontal>
          <b-field :label="$t('From :')" horizontal>
            <b-datepicker
              ref="Date From"
              v-model="dateFrom"
              expanded
              name="Date From"
              :placeholder="$t('Earliest')"
              icon="calendar-minus"
              :date-formatter="$d"
              :first-day-of-week="1"
              :max-date="dateTo"
              @input="changeDateRange()"
            />
          </b-field>
          <b-field :label="$t('To :')" horizontal>
            <b-datepicker
              ref="Date To"
              v-model="dateTo"
              expanded
              name="Date To"
              :placeholder="$t('Latest')"
              icon="calendar-plus"
              :date-formatter="$d"
              :first-day-of-week="1"
              :min-date="dateFrom"
              :max-date="yesterday"
              @input="changeDateRange()"
            />
          </b-field>
          <b-field horizontal>
            <b-button
              type="is-primary"
              icon-left="download"
              icon-pack="fad"
              @click="saveDailyCsv()"
            >
              {{ $t('Export daily stats') }}
            </b-button>
            <b-button
              type="is-primary"
              icon-left="sync-alt"
              icon-pack="fad"
              @click="fetchData(fetchedFromDate, dateTo)"
            >
              {{ $t('Retrieve Data') }}
            </b-button>
          </b-field>
        </b-field>
      </div>
    </div>
    <div class="tile is-parent">
      <div class="tile is-child box">
        <p class="has-text-info is-size-4 has-text-weight-semibold">
          {{ $t('OMW Statistics') }}
        </p>
        <notifications-clicks
          v-if="omwCoreStats"
          :index-from="indexFrom"
          :index-to="indexTo"
          :chart-type="chartType"
          :chart-labels="labels"
          :notifications="notifications"
          :initial-notifications="initialNotifications"
          :page-loads="pageLoads"
          :initial-page-loads="initialPageLoads"
          :show-notifications="showNotifications"
          :show-initial-notifications="showInitialNotifications"
          :show-page-loads="showPageLoads"
          :show-initial-page-loads="showInitialPageLoads"
          :graph-loading="graphLoading"
        />
        <div class="columns mt-2">
          <div v-if="$configData.omwStats.showNotificationStats" class="column">
            <b-tooltip
              :label="
                $t(
                  'Total notifications sent to customers. This could include second/third reminder notifications.',
                )
              "
              multilined
            >
              <b-checkbox ref="showNotifications" v-model="showNotifications">
                {{ $t('Notifications sent') }}
              </b-checkbox>
            </b-tooltip>
          </div>
          <div v-if="$configData.omwStats.showNotificationStats" class="column">
            <b-tooltip
              :label="$t('Total number of initial notifications sent.')"
              multilined
            >
              <b-checkbox
                ref="showInitialNotifications"
                v-model="showInitialNotifications"
              >
                {{ $t('Activities') }}
              </b-checkbox>
            </b-tooltip>
          </div>
          <div class="column">
            <b-tooltip
              :label="
                $t(
                  'Total number initial page loads. I.e. how many customers have clicked the link one or more times.',
                )
              "
              multilined
            >
              <b-checkbox
                ref="showInitialPageLoads"
                v-model="showInitialPageLoads"
              >
                {{ $t('Engaged activities') }}
              </b-checkbox>
            </b-tooltip>
          </div>
          <div class="column">
            <b-tooltip
              :label="
                $t(
                  'Total page loads. Customer might refresh the page or re-click the link multiple times, so this is likely to be high.',
                )
              "
              multilined
            >
              <b-checkbox ref="showPageLoads" v-model="showPageLoads">
                {{ $t('Page loads') }}
              </b-checkbox>
            </b-tooltip>
          </div>
          <div class="column is-pulled-right">
            <div class="block is-pulled-right">
              <b-radio v-model="chartType" name="chartType" native-value="bar">
                {{ $t('Bar chart') }}
              </b-radio>
              <b-radio v-model="chartType" name="chartType" native-value="line">
                {{ $t('Line chart') }}
              </b-radio>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="rollingAveragePercentageEngagedActivities"
      class="tile is-parent"
    >
      <div class="tile is-child box">
        <p class="has-text-info is-size-4 has-text-weight-semibold">
          {{ $t('7-day rolling average % engaged activities') }}
        </p>
        <average-engaged-activities
          v-if="omwCoreStats"
          :index-from="indexFrom"
          :index-to="indexTo"
          :chart-labels="labels"
          :rolling-average-percentage-engaged-activities="
            rollingAveragePercentageEngagedActivities
          "
          :graph-loading="graphLoading"
        />
      </div>
    </div>
    <div class="tile is-parent">
      <div class="tile is-child box">
        <p class="has-text-info is-size-4 has-text-weight-semibold">
          {{ $t('7-day rolling average page loads per engaged activity') }}
        </p>
        <average-page-loads
          v-if="omwCoreStats"
          :index-from="indexFrom"
          :index-to="indexTo"
          :chart-labels="labels"
          :rolling-average-page-loads-per-engaged-activity="
            rollingAveragePageLoadsPerEngagedActivity
          "
          :graph-loading="graphLoading"
        />
      </div>
    </div>
    <div class="tile is-parent">
      <div class="tile is-child box">
        <p class="has-text-info is-size-4 has-text-weight-semibold">
          {{ $t('Monthly Summaries') }}
        </p>
        <monthly-table
          v-if="omwCoreStats"
          :monthly-summaries="monthlySummaries"
          :loading-monthly-stats="loadingMonthlyStats"
          @load-more-months="loadMoreMonths"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import Papa from 'papaparse';
import saveFile from 'save-as-file';

import { retrieveStats } from '@/services/omwStatsService';
import NotificationsClicks from './NotificationsClicks';
import AverageEngagedActivities from './AverageEngagedActivities';
import AveragePageLoads from './AveragePageLoads';
import MonthlyTable from './MonthlyTable';

export default {
  name: 'OmwStats',
  components: {
    NotificationsClicks,
    AverageEngagedActivities,
    MonthlyTable,
    AveragePageLoads,
  },
  data() {
    return {
      summaryMonthRows: 0,
      fetchedFromDate: DateTime.now()
        .startOf('month')
        .minus({ Months: this.$configData.omwStats.monthsPerRequest - 1 })
        .toJSDate(),
      dateFrom: DateTime.now()
        .startOf('day')
        .minus({ Months: 1 })
        .minus({ Days: 1 })
        .toJSDate(),
      dateTo: DateTime.now().startOf('day').minus({ Days: 1 }).toJSDate(),
      yesterday: DateTime.now().startOf('day').minus({ Days: 1 }).toJSDate(),
      indexFrom: 0,
      indexTo: 0,
      omwCoreStats: null,
      isLoading: true,
      graphLoading: false,
      chartType: 'bar',
      showNotifications: true,
      showInitialNotifications: true,
      showPageLoads: true,
      showInitialPageLoads: true,
      monthlySummaries: [],
      loadingMonthlyStats: false,
      error: undefined,
    };
  },
  computed: {
    labels() {
      if (!this.omwCoreStats) return [];
      return this.omwCoreStats.map((stat) => {
        return stat.date;
      });
    },
    notifications() {
      // Stat 1
      if (!this.$configData.omwStats.showNotificationStats) return null;
      if (!this.omwCoreStats) return [];
      return this.omwCoreStats.map((stat) => {
        return stat.notifications;
      });
    },
    initialNotifications() {
      // Stat 2 (a.k.a. Number of activities)
      if (!this.$configData.omwStats.showNotificationStats) return null;
      if (!this.omwCoreStats) return [];
      return this.omwCoreStats.map((stat) => {
        return stat.initialNotifications;
      });
    },
    initialPageLoads() {
      // Stat 3 (a.k.a. Number of engaged activities)
      if (!this.omwCoreStats) return [];
      return this.omwCoreStats.map((stat) => {
        return stat.initialPageLoads;
      });
    },
    rollingAveragePercentageEngagedActivities() {
      // Stat 4
      if (!this.$configData.omwStats.showNotificationStats) return null;
      const rollingAverage = [];
      const total = this.notifications.length;
      for (let x = 0; x < total; x++) {
        if (x < 3 || x > total - 4) {
          rollingAverage.push(null);
        } else {
          let totalInitialPageLoads = 0;
          let totalInitialNotifications = 0;
          for (let y = 0; y < 7; y++) {
            totalInitialPageLoads += this.initialPageLoads[x + y];
            totalInitialNotifications += this.initialNotifications[x + y];
          }
          const averageInitialPageLoads = totalInitialPageLoads / 7;
          const averageInitialNotifications = totalInitialNotifications / 7;
          const percent =
            (averageInitialPageLoads / averageInitialNotifications) * 100;
          rollingAverage.push(percent);
        }
      }
      return rollingAverage;
    },
    pageLoads() {
      // Stat 5
      if (!this.omwCoreStats) return [];
      return this.omwCoreStats.map((stat) => {
        return stat.pageLoads;
      });
    },
    rollingAveragePageLoadsPerEngagedActivity() {
      // Stat 6
      const rollingAverage = [];
      const total = this.pageLoads.length;
      for (let x = 0; x < total; x++) {
        if (x < 3 || x > total - 4) {
          rollingAverage.push(null);
        } else {
          let totalPageLoads = 0;
          let totalInitialPageLoads = 0;
          for (let y = 0; y < 7; y++) {
            totalPageLoads += this.pageLoads[x + y];
            totalInitialPageLoads += this.initialPageLoads[x + y];
          }
          const averagePageLoads = totalPageLoads / 7;
          const averageInitialPageLoads = totalInitialPageLoads / 7;
          const averageLoadOverInitialLoad =
            averagePageLoads / averageInitialPageLoads;
          rollingAverage.push(averageLoadOverInitialLoad.toFixed(1));
        }
      }
      return rollingAverage;
    },
  },
  async mounted() {
    try {
      await this.fetchData(this.fetchedFromDate, this.dateTo);
      this.calculateMonthlySummaries();
      if (this.$configData.omwStats.showNotificationStats) {
        this.$refs.showNotifications.$el.querySelector(
          '.b-checkbox.checkbox input[type=checkbox]:checked + .check',
        ).style.backgroundColor = this.statsColors.notifications;
        this.$refs.showNotifications.$el.querySelector(
          '.b-checkbox.checkbox input[type=checkbox]:checked + .check',
        ).style.borderColor = this.statsColors.notifications;
        this.$refs.showInitialNotifications.$el.querySelector(
          '.b-checkbox.checkbox input[type=checkbox]:checked + .check',
        ).style.backgroundColor = this.statsColors.initialNotifications;
        this.$refs.showInitialNotifications.$el.querySelector(
          '.b-checkbox.checkbox input[type=checkbox]:checked + .check',
        ).style.borderColor = this.statsColors.initialNotifications;
      }
      this.$refs.showInitialPageLoads.$el.querySelector(
        '.b-checkbox.checkbox input[type=checkbox]:checked + .check',
      ).style.backgroundColor = this.statsColors.initialPageLoads;
      this.$refs.showInitialPageLoads.$el.querySelector(
        '.b-checkbox.checkbox input[type=checkbox]:checked + .check',
      ).style.borderColor = this.statsColors.initialPageLoads;
      this.$refs.showPageLoads.$el.querySelector(
        '.b-checkbox.checkbox input[type=checkbox]:checked + .check',
      ).style.backgroundColor = this.statsColors.pageLoads;
      this.$refs.showPageLoads.$el.querySelector(
        '.b-checkbox.checkbox input[type=checkbox]:checked + .check',
      ).style.borderColor = this.statsColors.pageLoads;
    } catch (err) {
      this.error = `${this.$t('stats-failure')}: ${err.message}`;
    } finally {
      this.isLoading = false;
      this.loadingMonthlyStats = false;
    }
  },
  methods: {
    async fetchData(fetchFromDate, fetchToDate) {
      this.error = undefined;
      try {
        this.graphLoading = true;
        this.error = null;
        const retrievedStats = await retrieveStats(
          DateTime.fromJSDate(fetchFromDate).toFormat('yyyy-MM-dd'),
          DateTime.fromJSDate(fetchToDate).toFormat('yyyy-MM-dd'),
        );
        if (!this.omwCoreStats) this.omwCoreStats = [];
        let datePointer = DateTime.fromJSDate(fetchToDate);
        let indexPointer = retrievedStats.length - 1;
        while (datePointer >= DateTime.fromJSDate(fetchFromDate)) {
          if (
            DateTime.fromFormat(
              `${retrievedStats[indexPointer]?.yearMonth}-${retrievedStats[indexPointer]?.day}`,
              'yyyy-MM-d',
            ).toISO() === datePointer.toISO()
          ) {
            this.omwCoreStats.push({
              date: datePointer.toFormat('yyyy-MM-dd'),
              notifications: retrievedStats[indexPointer]?.notifications
                ? retrievedStats[indexPointer]?.notifications
                : 0,
              initialNotifications: retrievedStats[indexPointer]
                ?.initialNotifications
                ? retrievedStats[indexPointer]?.initialNotifications
                : 0,
              initialPageLoads: retrievedStats[indexPointer]?.initialPageLoads
                ? retrievedStats[indexPointer]?.initialPageLoads
                : 0,
              pageLoads: retrievedStats[indexPointer]?.pageLoads
                ? retrievedStats[indexPointer]?.pageLoads
                : 0,
            });
            indexPointer--;
          } else {
            this.omwCoreStats.push({
              date: datePointer.toFormat('yyyy-MM-dd'),
              notifications: 0,
              initialNotifications: 0,
              initialPageLoads: 0,
              pageLoads: 0,
            });
          }
          datePointer = datePointer.minus({ Days: 1 });
        }
        this.indexFrom = this.calculateIndex(this.dateFrom);
        this.indexTo = this.calculateIndex(this.dateTo);
        this.fetchedFromDate = fetchFromDate;
      } catch (err) {
        this.error = err.message;
      } finally {
        this.graphLoading = false;
      }
    },
    changeDateRange() {
      if (this.dateFrom < this.fetchedFromDate) {
        const fetchToDate = DateTime.fromJSDate(this.fetchedFromDate)
          .minus({ days: 1 })
          .toJSDate();
        let fetchFromDate = DateTime.fromJSDate(this.fetchedFromDate)
          .minus({ months: this.$configData.omwStats.monthsPerRequest })
          .startOf('month')
          .toJSDate();
        while (fetchFromDate > this.dateFrom) {
          fetchFromDate = DateTime.fromJSDate(fetchFromDate)
            .minus({ months: this.$configData.omwStats.monthsPerRequest })
            .startOf('month')
            .toJSDate();
        }
        this.fetchData(fetchFromDate, fetchToDate);
      } else {
        this.indexFrom = this.calculateIndex(this.dateFrom);
        this.indexTo = this.calculateIndex(this.dateTo);
      }
    },
    calculateIndex(date) {
      const dateDiff = DateTime.fromJSDate(date).diff(
        DateTime.fromJSDate(this.yesterday),
        'days',
      );
      return Math.floor(dateDiff.days * -1);
    },
    async loadMoreMonths() {
      this.loadingMonthlyStats = true;
      const fetchFromDate = DateTime.now()
        .startOf('month')
        .minus({
          Months:
            this.summaryMonthRows +
            this.$configData.omwStats.monthsPerRequest -
            1,
        })
        .toJSDate();
      const fetchToDate = DateTime.fromJSDate(this.fetchedFromDate)
        .minus({ days: 1 })
        .toJSDate();
      if (fetchFromDate < this.fetchedFromDate) {
        await this.fetchData(fetchFromDate, fetchToDate);
      }
      this.calculateMonthlySummaries();
      this.loadingMonthlyStats = false;
    },
    calculateMonthlySummaries() {
      this.loadingMonthlyStats = true;
      for (
        let monthNum = this.summaryMonthRows;
        monthNum <=
        this.summaryMonthRows + this.$configData.omwStats.monthsPerRequest - 1;
        monthNum++
      ) {
        const monthYearLabel = DateTime.now()
          .minus({ Months: monthNum })
          .toFormat('MMM-yy');
        let datePointer = DateTime.now()
          .minus({ Months: monthNum })
          .startOf('month');
        const monthEnd =
          monthNum === 0
            ? DateTime.now().minus({ Days: 1 }).startOf('day')
            : DateTime.now().minus({ Months: monthNum }).endOf('month');
        let totalNotifications = 0;
        let totalInitialNotifications = 0;
        let totalInitialPageLoads = 0;
        let totalPageLoads = 0;
        while (datePointer < monthEnd) {
          const indexPointer = this.calculateIndex(datePointer.toJSDate());
          if (this.notifications) {
            totalNotifications += this.notifications[indexPointer];
          }
          if (this.initialNotifications) {
            totalInitialNotifications +=
              this.initialNotifications[indexPointer];
          }
          totalInitialPageLoads += this.initialPageLoads[indexPointer];
          totalPageLoads += this.pageLoads[indexPointer];
          datePointer = datePointer.plus({ Day: 1 });
        }
        const percentageEngaged =
          totalInitialNotifications !== 0
            ? (totalInitialPageLoads / totalInitialNotifications) * 100
            : 0;
        const averagePageLoads =
          totalInitialPageLoads !== 0
            ? totalPageLoads / totalInitialPageLoads
            : 0;
        const monthlySummary = {
          monthYearLabel,
          ...(this.notifications && { totalNotifications }),
          ...(this.initialNotifications && { totalInitialNotifications }),
          totalInitialPageLoads,
          ...(this.initialNotifications && {
            percentageEngaged: percentageEngaged.toFixed(1),
          }),
          totalPageLoads,
          averagePageLoads: averagePageLoads.toFixed(1),
        };
        this.monthlySummaries.push(monthlySummary);
      }
      this.summaryMonthRows += this.$configData.omwStats.monthsPerRequest;
    },
    saveDailyCsv() {
      // FIXME headings
      const data = this.omwCoreStats.slice(this.indexTo, this.indexFrom + 1);
      const csv = Papa.unparse(data);
      const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
      saveFile(
        blob,
        `StatsOutput_${DateTime.now().toFormat('dd-MM-yyyy_HH_mm_ss')}.csv`,
      );
    },
  },
};
</script>

<style scoped></style>
