<template>
  <div id="container">
    <b-loading :is-full-page="false" :active="graphLoading"></b-loading>
    <canvas ref="average-page-loads-per-activity-chart" />
  </div>
</template>

<script>
import Chart from 'chart.js';
let chart;

export default {
  name: 'AveragePageLoads',
  props: {
    indexFrom: {
      type: Number,
      required: true,
    },
    indexTo: {
      type: Number,
      required: true,
    },
    chartLabels: {
      type: Array,
      required: true,
    },
    rollingAveragePageLoadsPerEngagedActivity: {
      type: Array,
      required: true,
    },
    graphLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      indexFromPointer: null,
      indexToPointer: null,
      omwChartData: {
        type: 'line',
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: { display: false },
          scales: {
            xAxes: [
              {
                ticks: {
                  reverse: true,
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'Page loads',
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      },
    };
  },
  watch: {
    indexFrom() {
      while (this.indexFrom > this.indexFromPointer) {
        this.indexFromPointer++;
        chart.data.datasets[0].data.push(
          this.rollingAveragePageLoadsPerEngagedActivity[this.indexFromPointer],
        );
        chart.data.labels.push(this.chartLabels[this.indexFromPointer]);
      }
      while (this.indexFrom < this.indexFromPointer) {
        this.indexFromPointer--;
        chart.data.datasets[0].data.pop();
        chart.data.labels.pop();
      }
      chart.update();
    },
    indexTo() {
      while (this.indexTo < this.indexToPointer) {
        this.indexToPointer--;
        chart.data.datasets[0].data.unshift(
          this.rollingAveragePageLoadsPerEngagedActivity[this.indexToPointer],
        );
        chart.data.labels.unshift(this.chartLabels[this.indexToPointer]);
      }
      while (this.indexTo > this.indexToPointer) {
        this.indexToPointer++;
        chart.data.datasets[0].data.shift();
        chart.data.labels.shift();
      }
      chart.update();
    },
  },
  mounted() {
    this.indexFromPointer = this.indexFrom;
    this.indexToPointer = this.indexTo;
    const ctx = this.$refs['average-page-loads-per-activity-chart'];
    this.omwChartData.data.datasets = [
      {
        label: '7 day rolling average page-loads per engaged activity',
        fill: false,
        borderColor: this.statsColors.averagePageLoads,
        tension: 0.4,
        data: this.rollingAveragePageLoadsPerEngagedActivity.slice(
          0,
          this.indexFrom + 1,
        ),
      },
    ];
    this.omwChartData.data.labels = this.chartLabels.slice(
      0,
      this.indexFrom + 1,
    );
    chart = new Chart(ctx, this.omwChartData);
  },
};
</script>

<style scoped>
#container {
  position: relative;
  height: 30vh;
}
</style>
