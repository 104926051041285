import axios from 'axios';
import configData from '../config/config.json';
import { getIdToken } from '@/services/auth/index';

const apiClient = axios.create({
  baseURL: configData.omwStats.baseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getIdToken();
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    }
    throw new Error('No access token Found');
  },
  (err) => Promise.reject(err),
);

export async function retrieveStats(dateFrom, dateTo) {
  const params = new URLSearchParams();
  let url = 'stats/core';

  params.append('dateFrom', dateFrom);
  params.append('dateTo', dateTo);

  const options = {
    method: 'GET',
    params,
    url,
  };
  const { data } = await apiClient(options);
  return data;
}
